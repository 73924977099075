import("./ServicesComponent.css");


export default function ServicesComponent() {
    return (
        <div className="fullContainerServices">
            <div className="container servicesContainer">
                <div className="service">
                    <h2 className="servicesTitle">Servicios completos para tú negocio, te damos más por tú dinero.</h2>
                    <p>"Los tiempos actuales exigen procesos sofisticados, establecidos y confiables cuando de limpieza se trata. En Pulylim comprendemos la importancia y severidad
                        de confiar nuestra seguridad sanitaria. Es por eso que ponemos a tu disposición los más completos servicios de limpiza activa y pasiva.
                        Nos enfocamos en proveer la seguridad sanitaria que tú y tú negocio necesita.
                        "
                    </p>
                </div>
                <div className="service">
                    <p>
                        El 93.2 % de las empresas registró al menos un tipo de afectación debido a la contingencia sanitaria
                        por Covid-19. La mayor afectación fue la disminución de los ingresos, con 91.3 por ciento. Le siguió la
                        baja demanda que a nivel nacional se reportó en 72.6% de las empresas consultadas. Datos Inegi 2021
                    </p>
                    <div className="serviceLogo">
                        <img className="serviceLogo1" src="images/cv19.png" alt="imagen los impactos del covid" />
                    </div>
                </div>
                <div className="service">
                    <h3>Sanitización Profesional</h3>
                    <p>Sanitización y termonebulización de todo tipo de inmuebles, que le permitan a tí y a tus empleados la confianza de trabajar en un entorno seguro.</p>
                    <div className="serviceLogo">
                        <img className="serviceLogo1" src="images/mask.png" alt="sanitización" />
                    </div>
                </div>
                <div className="service">
                    <h3>Limpieza</h3>
                    <ul className="list listaLimpieza">
                        <li>Limpieza corporativa (fina y/o gruesa) </li>
                        <li>Limpieza residencial y para eventos</li>
                        <li>Limpieza de piso a techo en todos los acabados de mobiliario </li>
                        <li>Limpieza de asperdores de aire</li>
                    </ul>
                    <div className="serviceLogo">
                        <img className="serviceLogo1" src="images/cleaning.png" alt="Servicio de pulimiento de pisos de alta calidad." />
                    </div>
                </div>
                <div className="service">
                    <h3>Lavado de vidrios</h3>
                    <p>Realizamos el lavado de vidrios para alturas menores de 2.5m. </p>
                    <div className="serviceLogo">
                        <img className="serviceLogo1" src="images/window.png" alt="ventana limpia" />
                    </div>
                </div>
                <div className="service">
                    <h3>Tratamiento de pisos</h3>
                    <ul className="list listaPisos">
                        <li>Pulido de mármol</li>
                        <li>Abrillantado y lavado de porcelanatos</li>
                        <li>Lavado y encerado de loseta vinílica</li>
                        <li>Lavado y encerado de parket y duela</li>
                        <li>Lavado de alfombras</li>
                    </ul>
                    <div className="serviceLogo">
                        <img className="serviceLogo1" src="images/polisher.png" alt="Servicio de pulimiento de pisos de alta calidad." />
                    </div>
                </div>
                <div className="service">
                    <h3>Jardinería</h3>
                    <ul className="list listaPisos">
                        <li>Mantenimiento preventivo</li>
                        <li>Poda de jardines y arbustos</li>
                        <li>Desramado de arbustos y árboles</li>
                    </ul>
                    <div className="serviceLogo">
                        <img className="serviceLogo1" src="images/gardening.png" alt="Servicios de jardinería profesionales." />
                    </div>
                </div>
            </div>
        </div>
    );
}
