import React from 'react';
import ImageGallery from 'react-image-gallery';
import("./ClientsComponent.css");

export default function ClientsComponent() {
    const presetImages = [
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1717864518/puliclean/imagenes2024/2024_1.jpg',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1717864518/puliclean/imagenes2024/2024_7.jpg',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1717864518/puliclean/imagenes2024/2024_8.jpg',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1717864518/puliclean/imagenes2024/2024_4.jpg',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1717864518/puliclean/imagenes2024/2024_5.jpg',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/1.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/2.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/3.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/4.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/5.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/6.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/7.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/8.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/9.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/10.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/11.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/12.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/13.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/14.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/16.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/17.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/18.webp',
        },
        {
            original: 'https://res.cloudinary.com/dkzkb69pj/image/upload/v1675141452/puliclean/servicios/19.webp',
        }

    ];

    return (
        <div className="sectionContainer fullContainerClients">
            <div className="container">
                <section className="ourFacts">
                    <div className="fact">
                        <h3>Equipo de trabajo</h3>
                        <p>Contamos con una plantilla de trabajadores especializados y comprometidos para ofrecer servicios de calidad a nuestros clientes.</p>
                    </div>
                    <div className="fact">
                        <h3>Experiencia</h3>
                        <p>Contamos con experiencia en distintas áreas e industrias.</p>
                        <ul>
                            <li>Corporativos</li>
                            <li>Bancos</li>
                            <li>Industria farmacéutica Residencias</li>
                        </ul>
                    </div>
                    <div className="fact">
                        <h3>Beneficios</h3>
                        <ul>
                            <li>
                                Capacitación constante
                            </li>
                            <li>
                                Personal uniformado
                            </li>
                            <li>
                                Supervisión
                            </li>
                            <li>
                                Disminución de carga administrativa
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="images">
                    <ImageGallery autoPlay items={presetImages}/>
                </section>
                <section className="trusters">
                    <h3>Estos clientes ya confían en nosotros</h3>
                    <div className='clientsLogos'>
                        <div className="logo">
                            <img width="45%" alt="merik" src="https://res.cloudinary.com/dkzkb69pj/image/upload/v1675226946/puliclean/clientsLogos/1.webp" />
                        </div>
                        <div className="logo">
                            <img alt="custik graphics" width="45%" src="https://res.cloudinary.com/dkzkb69pj/image/upload/v1675226946/puliclean/clientsLogos/2.webp" />
                        </div>
                        <div className="logo">
                            <img alt="corredor inmobiliario" width="45%" src="https://res.cloudinary.com/dkzkb69pj/image/upload/v1675226946/puliclean/clientsLogos/3.webp" />
                        </div>
                        <div className="logo">
                            <img alt="kolors" width="45%" src="https://res.cloudinary.com/dkzkb69pj/image/upload/v1675226946/puliclean/clientsLogos/4.webp" />
                        </div>
                        <div className="logo">
                            <img alt="avantor" width="45%" src="https://res.cloudinary.com/dkzkb69pj/image/upload/v1675226946/puliclean/clientsLogos/5.webp" />
                        </div>
                        <div className="logo">
                            <img alt="marriot" width="45%" src="https://res.cloudinary.com/dkzkb69pj/image/upload/v1675226946/puliclean/clientsLogos/6.webp" />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
