import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import ContactComponent from './sections/contact-component/ContactComponent';
import MainLayoutComponent from './sections/mainLayout-component/MainLayoutComponent';
import HomeComponent from './sections/home-component/HomeComponent';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <MainLayoutComponent /> } >
          <Route index path="/" element= { <HomeComponent /> } />
          <Route path="contacto" element={ <ContactComponent /> } />
          <Route path="*" element= { <HomeComponent /> } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;

