import { useState } from 'react';
import { Link } from "react-router-dom";

import("./ContactComponent.css");


export default function ContactComponent() {

    const [completedEmailContact, setcompleteEmailContactState] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    let contactForm =
        <form onSubmit={enviarDatos} id="contactForm" className="form">
            <div className="question">
                <p className="questionLabel">Nombre:</p>
                <input required type="text"></input>
            </div>
            <div className="question">
                <p className="questionLabel">Correo electrónico:</p>
                <input required type="email"></input>
            </div>
            <div className="question">
                <p className="questionLabel">Teléfono de Contacto:</p>
                <input required minLength="6" type="number"></input>
            </div>
            <div className="question">
                <p className="questionLabel">Servicio Requerido:</p>
                <textarea minLength="15" required rows="4" cols="20">
                </textarea>
            </div>
            <div className="submitBottom">
                <button type='submit' value="Submit"><span>Enviar</span></button>
                <Link to="/">{"<< Volver"}</Link>
            </div>
        </form>
        ;

    function setFormControlsDisabledState(formControls, disabled = true) {
        if (disabled) {
            formControls.forEach((formControl) => {
                formControl.setAttribute('disabled', disabled);
            });
        } else {
            formControls.forEach((formControl) => {
                formControl.removeAttribute('disabled');
            });
        }
    }

    function enviarDatos(event) {
        event.preventDefault();
        var form = document.getElementById("contactForm");
        let nameInput = form[0];
        let emailInput = form[1];
        let phoneInput = form[2];
        let descriptionInput = form[3];
        setFormControlsDisabledState([nameInput, emailInput, phoneInput, descriptionInput, event.target], true);
        let name = nameInput.value || "No se insertó nombre";
        let email = emailInput.value || "No se insertó email";
        let phone = phoneInput.value || "No se insertó teléfono";
        let description = descriptionInput.value || "";
        if (description !== "") {
            fetch("https://beakmovilservices-pulilym.up.railway.app/contacto", {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({ host: "Página Web", name, email, phone, description })
            }).then((resolve) => {
                if (resolve.status === 200) {
                    setcompleteEmailContactState(true);
                }
            }).catch(() => {
                setFormControlsDisabledState([nameInput, emailInput, phoneInput, descriptionInput, event.target], false);
                setShowErrorMessage(true);
            });
        }
    }

    return (
        <div className="main">
            <div className="container">
                <section className="contactForm">
                    <div className="formTitle">
                        <h3>Contácta con Nosotros.</h3>
                        <img alt="contacto con pulylim" width="125" src="https://res.cloudinary.com/dkzkb69pj/image/upload/v1675039358/puliclean/logo1.png" />
                    </div>
                    {
                        function () {
                            if (completedEmailContact) {
                                return (<div className="completedMessage"><h2>Gracias, se ha enviado tu email; nos pondremos en contácto contigo a la brevedad.</h2>  <button><Link to="/">Volver</Link></button>  </div>);
                            }
                            else if (showErrorMessage) {
                                return (<><h2>Ocurrió un error al enviar tú comentario porfavor reintenta o contáctanos por estos medios alternos</h2></>);
                            } else {
                                return (contactForm);
                            }
                        }()
                    }
                </section>
            </div>
        </div>
    );
}
