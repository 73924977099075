import './TopBannerComponent.css';

import { Link } from "react-router-dom";

export default function TopBannerComponent() {
    return (
        <section className="topBannerContainer">
            <div className="bannerInfo">
                <div  className="innerBannerInfo">
                    <a href="https://www.google.com">
                        <img className="mainLogo" alt="Logo puliclean" src="https://res.cloudinary.com/dkzkb69pj/image/upload/v1675039358/puliclean/logo1.png"/>
                    </a>
                    <div className="rightData">
                        <a target="_blank" rel="noreferrer" href="tel:5527608225">Oficina: 55-27-60-82-25</a>
                        <a target="_blank" rel="noreferrer" href="https://wa.me/5520069257/?text=Buen%20d%C3%ADa%20estoy%20interesado%20en%20un%20servicio%20o%20cotizaci%C3%B3n.">WhatsApp: 55-20-06-92-57</a>
                        <a target="_blank" rel="noreferrer" href="mailto:contacto@pulylim.com">contacto@pulylim.com</a>
                    </div>
                </div>
            </div>
            <div className="videoContainer">
                <section className="mainContactContainer">
                    <h1>Limpieza profesional para tu negocio. Mejores ambientes mayor productividad.</h1>
                    <button className="mainContact">
                        <Link to="/contacto"><b>Solicitar Cotización.</b></Link>
                    </button>
                </section>
                <video muted loop autoPlay  src="https://res.cloudinary.com/dkzkb69pj/video/upload/v1675310130/puliclean/completo2.mov"></video>
            </div>
        </section>
    );

} 
