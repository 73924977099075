import {Link} from 'react-router-dom';
import("./FooterComponent.css");

export default function FooterComponent() {
    return (
        <div className="fullContainerFooter">
        <div className="container">
            <section className="sectionFooter leftSide">
            <Link to="/contacto"><button className="mainContact"><b>Solicitar Cotización</b></button></Link>
            </section>
            <section className="sectionFooter middleSide">
                <p className="address">Melchor Ocampo 193 Edificio Torre C, Piso 6 Oficina C,Veronica Anzures, Miguel Hidalgo, Miguel Hidalgo, Ciudad de Mèxico, CP 11300.</p>
                <figure><a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100091831535737&mibextid=ZbWKwL"><img width="25" src="logos/face_logo.png" alt="FacebookLogo"/></a></figure>
                <figure><a target="_blank" rel="noreferrer" href="https://wa.me/5520069257/?text=Buen%20d%C3%ADa%20estoy%20interesado%20en%20un%20servicio%20o%20cotizaci%C3%B3n."><img width="25" src="logos/whatsapp_logo.png" alt="WhatsApp logo"/></a></figure>
                <figure><a target="_blank" rel="noreferrer" href="tel:5527608225"><img width="25" src="logos/phone_logo.png" alt="phone logo"/></a></figure>
            </section>
            <section className="sectionFooter rightSide">
                <p>Pulylim S.A de C.V.</p>
            </section>
        </div>
    </div>
    );
}
