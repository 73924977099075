
import TopBannerComponent from '../top-banner-component/TopBannerComponent.js';
import ServicesComponent from '../services-component/ServicesComponent.js';
import ClientsComponent from '../clients-component/ClientsComponent.js';
import FooterComponent from '../footer-component/FooterComponent.js';

export default function HomeComponent() {
    return (
        <main className='main-container'>
            <TopBannerComponent></TopBannerComponent>
            <section className="services">
                <ServicesComponent></ServicesComponent> 
            </section>
            <section className="clients">
                <ClientsComponent></ClientsComponent>
            </section>
            <section className="footer">
                <FooterComponent></FooterComponent>
            </section>
        </main>
    );
};